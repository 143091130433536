import {PerformanceChart} from "@/layouts/workspace/dashboard/performanceChart";
import {DateMode, HourMode, MonthMode} from "@/layouts/workspace/dashboard/modes";
import {IChartContext} from "@/layouts/workspace/dashboard/dashboard";

export interface IAppItem {
    name: string
    charts: IChartContext[]
}

export const apps: { [key: string]: IAppItem } = {
    gdb: {
        name: '管店宝', charts: [
            new PerformanceChart('出票性能趋势', 12, [new HourMode(), new DateMode(), new MonthMode()], [
                {
                    name: '速度最快',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from min(age(stop_at,start_at))) v from server.http where domain='cashier.mywsy.cn' and method='POST' and path~'bill' and start_at>_ROUND_ group by ts"
                    },
                    detail: {
                        db: 'market',
                        sql: "select id,open_id,info[0]->>'duration' duration, to_timestamp(((info[0]->>'startTime')::bigint)/1000) from h5.mini_program_logs where app_id='wxa127230cf851f2fb' and action='performance' and info[0]->>'name'='appLaunch' and create_at between _ROUND_ order by duration limit 40"
                    },
                    yAxisIndex: 1,
                },
                {
                    name: '平均耗时',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from avg(age(stop_at,start_at))) v from server.http where domain='cashier.mywsy.cn' and method='POST' and path~'bill' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '速度最慢',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from max(age(stop_at,start_at))) v from server.http where domain='cashier.mywsy.cn' and method='POST' and path~'bill' and start_at>_ROUND_ group by ts"
                    },
                    detail: {
                        db: 'market',
                        sql: "select id,open_id,info[0]->>'duration' duration, to_timestamp(((info[0]->>'startTime')::bigint)/1000) from h5.mini_program_logs where app_id='wxa127230cf851f2fb' and action='performance' and info[0]->>'name'='appLaunch' and create_at between _ROUND_ order by duration desc limit 40"
                    },
                    yAxisIndex: 1,
                },
                {
                    name: '出票数量',
                    type: 'bar',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,count(1) v from server.http where domain='cashier.mywsy.cn' and method='POST' and path~'bill' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                }
            ]),
        ]
    },



    djhdb: {
        name: '店家活动宝小程序',
        charts: [
            new PerformanceChart('冷启性能趋势', 12, [new HourMode(), new DateMode(), new MonthMode()], [
                {
                    name: '速度最快',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,min((info[0]->>'duration')::int)/1000 v from h5.mini_program_logs where app_id='wx8f794bec739bfcc7' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {
                        db: 'market',
                        sql: "select id,open_id,info[0]->>'duration' duration, to_timestamp(((info[0]->>'startTime')::bigint)/1000) from h5.mini_program_logs where app_id='wx8f794bec739bfcc7' and action='performance' and info[0]->>'name'='appLaunch' and create_at between _ROUND_ order by duration limit 40"
                    },
                    yAxisIndex: 1,
                },
                {
                    name: '平均耗时',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,avg((info[0]->>'duration')::int)/1000 v from h5.mini_program_logs where app_id='wx8f794bec739bfcc7' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '速度最慢',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,max((info[0]->>'duration')::int)/1000 v from h5.mini_program_logs where app_id='wx8f794bec739bfcc7' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {
                        db: 'market',
                        sql: "select id,open_id,info[0]->>'duration' duration, to_timestamp(((info[0]->>'startTime')::bigint)/1000) from h5.mini_program_logs where app_id='wx8f794bec739bfcc7' and action='performance' and info[0]->>'name'='appLaunch' and create_at between _ROUND_ order by duration desc limit 40"
                    },
                    yAxisIndex: 1,
                },
                {
                    name: '启动次数',
                    type: 'bar',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,count(1) v from h5.mini_program_logs where app_id='wx8f794bec739bfcc7' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                }
            ]),
            new PerformanceChart('会话性能趋势', 12, [new HourMode(), new DateMode(), new MonthMode()], [
                {
                    name: '速度最快',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from min(age(stop_at,start_at))) v from server.http where domain='api.activity.djhdb.cn' and method='GET' and path~'^/applet/open/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '平均耗时',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from avg(age(stop_at,start_at))) v from server.http where domain='api.activity.djhdb.cn' and method='GET' and path~'^/applet/open/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '速度最慢',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from max(age(stop_at,start_at))) v from server.http where domain='api.activity.djhdb.cn' and method='GET' and path~'^/applet/open/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '次数',
                    type: 'bar',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,count(1) v from server.http where domain='api.activity.djhdb.cn' and method='GET' and path~'^/applet/open/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                }
            ]),
            new PerformanceChart('活动数据接口性能趋势', 12, [new HourMode(), new DateMode(), new MonthMode()], [
                {
                    name: '速度最快',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from min(age(stop_at,start_at))) v from server.http where domain='api.activity.djhdb.cn' and method='GET' and path~'^/applet/activity' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '平均耗时',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from avg(age(stop_at,start_at))) v from server.http where domain='api.activity.djhdb.cn' and method='GET' and path~'^/applet/activity' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '速度最慢',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from max(age(stop_at,start_at))) v from server.http where domain='api.activity.djhdb.cn' and method='GET' and path~'^/applet/activity' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '次数',
                    type: 'bar',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,count(1) v from server.http where domain='api.activity.djhdb.cn' and method='GET' and path~'^/applet/activity' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                }
            ]),
            new PerformanceChart('头图合成接口性能趋势', 12, [new HourMode(), new DateMode(), new MonthMode()], [
                {
                    name: '速度最快',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from min(age(stop_at,start_at))) v from server.http where domain='api.activity.djhdb.cn' and method='POST' and path='/applet/activity/image/render?name=head' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '平均耗时',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from avg(age(stop_at,start_at))) v from server.http where domain='api.activity.djhdb.cn' and method='POST' and path='/applet/activity/image/render?name=head' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '速度最慢',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from max(age(stop_at,start_at))) v from server.http where domain='api.activity.djhdb.cn' and method='POST' and path='/applet/activity/image/render?name=head' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '次数',
                    type: 'bar',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,count(1) v from server.http where domain='api.activity.djhdb.cn' and method='POST' and path='/applet/activity/image/render?name=head' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                }
            ]),
            new PerformanceChart('海报图合成接口性能趋势', 12, [new HourMode(), new DateMode(), new MonthMode()], [
                {
                    name: '速度最快',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from min(age(stop_at,start_at))) v from server.http where domain='api.activity.djhdb.cn' and method='POST' and path='/applet/activity/image/render?name=wx_post' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '平均耗时',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from avg(age(stop_at,start_at))) v from server.http where domain='api.activity.djhdb.cn' and method='POST' and path='/applet/activity/image/render?name=wx_post' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '速度最慢',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from max(age(stop_at,start_at))) v from server.http where domain='api.activity.djhdb.cn' and method='POST' and path='/applet/activity/image/render?name=wx_post' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '次数',
                    type: 'bar',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,count(1) v from server.http where domain='api.activity.djhdb.cn' and method='POST' and path='/applet/activity/image/render?name=wx_post' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                }
            ]),
        ]
    },


    gdb_lite: {
        name: '管店宝极速版小程序',
        charts: [
            new PerformanceChart('冷启性能趋势', 12, [new HourMode(), new DateMode(), new MonthMode()], [
                {
                    name: '速度最快',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,min((info[0]->>'duration')::int)/1000 v from h5.mini_program_logs where app_id='wxa127230cf851f2fb' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {
                        db: 'market',
                        sql: "select id,open_id,info[0]->>'duration' duration, to_timestamp(((info[0]->>'startTime')::bigint)/1000) from h5.mini_program_logs where app_id='wxa127230cf851f2fb' and action='performance' and info[0]->>'name'='appLaunch' and create_at between _ROUND_ order by duration limit 40"
                    },
                    yAxisIndex: 1,
                },
                {
                    name: '平均耗时',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,avg((info[0]->>'duration')::int)/1000 v from h5.mini_program_logs where app_id='wxa127230cf851f2fb' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '速度最慢',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,max((info[0]->>'duration')::int)/1000 v from h5.mini_program_logs where app_id='wxa127230cf851f2fb' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {
                        db: 'market',
                        sql: "select id,open_id,info[0]->>'duration' duration, to_timestamp(((info[0]->>'startTime')::bigint)/1000) from h5.mini_program_logs where app_id='wxa127230cf851f2fb' and action='performance' and info[0]->>'name'='appLaunch' and create_at between _ROUND_ order by duration desc limit 40"
                    },
                    yAxisIndex: 1,
                },
                {
                    name: '启动次数',
                    type: 'bar',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,count(1) v from h5.mini_program_logs where app_id='wxa127230cf851f2fb' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                }
            ]),
            new PerformanceChart('会话性能趋势', 12, [new HourMode(), new DateMode(), new MonthMode()], [
                {
                    name: '速度最快',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from min(age(stop_at,start_at))) v from server.http where domain='lite.cashier.mywsy.cn' and method='GET' and path~'^/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '平均耗时',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from avg(age(stop_at,start_at))) v from server.http where domain='lite.cashier.mywsy.cn' and method='GET' and path~'^/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '速度最慢',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from max(age(stop_at,start_at))) v from server.http where domain='lite.cashier.mywsy.cn' and method='GET' and path~'^/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '会话数量',
                    type: 'bar',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,count(1) v from server.http where domain='lite.cashier.mywsy.cn' and method='GET' and path~'^/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                }
            ]),
        ]
    },


    mp_mall: {
        name: '会员账本小程序', charts: [
            new PerformanceChart('冷启性能趋势', 12, [new HourMode(), new DateMode(), new MonthMode()], [
                {
                    name: '速度最快',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,(min((info[0]->>'duration')::float)/1000)::numeric(8,2) v from h5.mini_program_logs where app_id='wx45e982557855ad39' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: "select now()"},
                    yAxisIndex: 1,
                },
                {
                    name: '平均耗时',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,(avg((info[0]->>'duration')::float)/1000)::numeric(8,2) v from h5.mini_program_logs where app_id='wx45e982557855ad39' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '速度最慢',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,(max((info[0]->>'duration')::float)/1000)::numeric(8,2) v from h5.mini_program_logs where app_id='wx45e982557855ad39' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {
                        db: 'market',
                        sql: "select id,open_id,(info[0]->>'duration')::bigint duration, to_timestamp(((info[0]->>'startTime')::bigint)/1000) from h5.mini_program_logs where app_id='wx45e982557855ad39' and action='performance' and info[0]->>'name'='appLaunch' and create_at between _ROUND_ order by duration desc limit 40"
                    },
                    yAxisIndex: 1,
                },
                {
                    name: '启动次数',
                    type: 'bar',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,count(1) v from h5.mini_program_logs where app_id='wx45e982557855ad39' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                }
            ]),
            new PerformanceChart('会话性能趋势', 12, [new HourMode(), new DateMode(), new MonthMode()], [
                {
                    name: '速度最快',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from min(age(stop_at,start_at))) v from server.http where domain='cashier.mywsy.cn' and method='GET' and path~'^/mp/member/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '平均耗时',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from avg(age(stop_at,start_at))) v from server.http where domain='cashier.mywsy.cn' and method='GET' and path~'^/mp/member/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '速度最慢',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from max(age(stop_at,start_at))) v from server.http where domain='cashier.mywsy.cn' and method='GET' and path~'^/mp/member/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '会话数量',
                    type: 'bar',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,count(1) v from server.http where domain='cashier.mywsy.cn' and method='GET' and path~'^/mp/member/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                }
            ]),
        ]
    },
    mp_tony:{
        name:'托尼账本小程序',charts:[
            new PerformanceChart('冷启性能趋势', 12, [new HourMode(), new DateMode(), new MonthMode()], [
                {
                    name: '速度最快',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,(min((info[0]->>'duration')::float)/1000)::numeric(8,2) v from h5.mini_program_logs where app_id='wxa98f4695db39a9f4' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: "select now()"},
                    yAxisIndex: 1,
                },
                {
                    name: '平均耗时',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,(avg((info[0]->>'duration')::float)/1000)::numeric(8,2) v from h5.mini_program_logs where app_id='wxa98f4695db39a9f4' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '速度最慢',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,(max((info[0]->>'duration')::float)/1000)::numeric(8,2) v from h5.mini_program_logs where app_id='wxa98f4695db39a9f4' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {
                        db: 'market',
                        sql: "select id,open_id,(info[0]->>'duration')::bigint duration, to_timestamp(((info[0]->>'startTime')::bigint)/1000) from h5.mini_program_logs where app_id='wxa98f4695db39a9f4' and action='performance' and info[0]->>'name'='appLaunch' and create_at between _ROUND_ order by duration desc limit 40"
                    },
                    yAxisIndex: 1,
                },
                {
                    name: '启动次数',
                    type: 'bar',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',create_at) ts,count(1) v from h5.mini_program_logs where app_id='wxa98f4695db39a9f4' and action='performance' and info[0]->>'name'='appLaunch' and create_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                }
            ]),
            new PerformanceChart('会话性能趋势', 12, [new HourMode(), new DateMode(), new MonthMode()], [
                {
                    name: '速度最快',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from min(age(stop_at,start_at))) v from server.http where domain='bookkeeping.tony.mywsy.cn' and method='GET' and path~'^/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '平均耗时',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from avg(age(stop_at,start_at))) v from server.http where domain='bookkeeping.tony.mywsy.cn' and method='GET' and path~'^/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '速度最慢',
                    type: 'line',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,extract ('epoch' from max(age(stop_at,start_at))) v from server.http where domain='bookkeeping.tony.mywsy.cn' and method='GET' and path~'^/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                    yAxisIndex: 1,
                },
                {
                    name: '会话数量',
                    type: 'bar',
                    series: {
                        db: 'market',
                        sql: "select date_trunc('_TS_',start_at) ts,count(1) v from server.http where domain='bookkeeping.tony.mywsy.cn' and method='GET' and path~'^/session' and start_at>_ROUND_ group by ts"
                    },
                    detail: {db: 'market', sql: 'select now()'},
                }
            ]),
        ]
    },
}