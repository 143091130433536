import {IBarDate} from "@/layouts/workspace/dashboard/dashboard";
import MixBar from "@/layouts/workspace/dashboard/mixBar";

export class PerformanceChart extends MixBar {

    data(): IBarDate[] {
        const arr = []
        for (const st of this.sqlTpl) {
            arr.unshift({
                type: st.type,
                name: st.name,
                data: this.mode.dataFormat(st.chartData!),
                showBackground: true,
                stack: st.yAxisIndex ? undefined : 'all',
                yAxisIndex: st.yAxisIndex
            })
        }
        return arr;
    }

}